
















































import { Vue, Component } from "vue-property-decorator";

@Component
export default class ModifyPassword extends Vue {
  isLoading = false;

  userData = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  };

  get rules() {
    return {
      oldPassword: [
        {
          required: true,
          message: "请输入原密码",
          trigger: "blur"
        }
      ],
      newPassword: [
        {
          required: true,
          message: "请输入密码",
          trigger: "blur"
        },
        {
          validator: (rule: any, value: string, callback: Function) => {
            if (value === this.userData.oldPassword) {
              callback(new Error("新密码不能与原密码相同"));
            } else {
              callback();
            }
          },
          trigger: "blur"
        }
      ],
      confirmPassword: [
        {
          required: true,
          message: "请再一次输入密码",
          trigger: "blur"
        },
        {
          validator: (rule: any, value: string, callback: Function) => {
            if (value !== this.userData.newPassword) {
              callback(new Error("两次输入的密码不一致"));
            } else {
              callback();
            }
          },
          trigger: "blur"
        }
      ]
    };
  }

  submitData() {
    alert("asdasd");
  }
}
